/* You can add global styles to this file, and also import other style files */

.pageImage {
  max-width: 400px; 
  float: right; 
  border-radius: 20px; 
  border-color: #b8b8b8; 
  border-width: thin; 
  border-style: solid; 
  margin-left: 25px; 
  margin-right: 25px; 
  margin-bottom: 25px; 
  box-shadow: 5px 5px 15px #444444;;
}

.pageHeader {
  padding-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  [class*="pageImage"] {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    // width: 50%;
  }

  [class*="pageHeader"] {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  [class*="service-details-content"] {
    margin-bottom: 60px;
  }
}